import React, { Component } from 'react';

class Footer extends Component {
    render () {
        return (
            <footer className="footer">
                Thanks for visiting my site!
            </footer>
        )
    }
}
export default Footer;
